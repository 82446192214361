import React, { Component, Suspense } from "react";
import { withRouter } from "react-router-dom";

import Footer from "./Footer"

const Loader = () => {
  return (
    <div id="preloader">
      <div id="status">
        <div className="spinner">
          <div className="double-bounce1"></div>
          <div className="double-bounce2"></div>
        </div>
      </div>
    </div>
  );
};

class Layout extends Component { 
  render() {
    return (
      <React.Fragment>
        <Suspense fallback={Loader()}>
        {/* <Topbar /> */}

        {this.props.children}
        <Footer />

        </Suspense>

      </React.Fragment>
    );
  }
}

export default withRouter(Layout);
