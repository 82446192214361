import React, { Suspense } from "react";
import Layout from "./components/Layout/";
import {
  Route,
  Switch,
  BrowserRouter as Router,
  withRouter,
} from "react-router-dom";

// Import Css
import "./Apps.scss";

import ReactGA from "react-ga4";

// Include Routes
import routes from "./routes/allRoutes";

function withLayout(WrappedComponent) {
  /* eslint-disable react/display-name */
  return class extends React.Component {
    render() {
      return (
        <Layout>
          <WrappedComponent></WrappedComponent>
        </Layout>
      );
    }
  };
}

const Loader = () => {
  return (
    <div id="preloader">
      <div id="status">
        <div className="spinner">
          <div className="double-bounce1"></div>
          <div className="double-bounce2"></div>
        </div>
      </div>
    </div>
  );
};

function App() {
  return (
    <React.Fragment>
      <Router>
      <Suspense fallback={Loader}>
          <Switch>
            {
              routes.map((route, idx) =>
                <Route
                  path={route.path}
                  exact
                  component={withLayout(route.component)}
                  key={idx}
                />
            )}
          </Switch>
        </Suspense>
      </Router>
    </React.Fragment>
  );
}

export default withRouter(App);
